<template>
  <!-- <v-row class="mt-5">
    <v-col cols="12" md="12" class="py-0 my-0">
      <h5>Corporate Rates</h5>
    </v-col>
    <v-col cols="12" md="12">
      <v-row v-for="(rate, index) in rates" :key="'rate_' + index">
        <v-col cols="12" md="3"> Starting Price </v-col>
        <v-col cols="12" md="3">
          € {{ rate.starting_price | commaSeparator }}
        </v-col>
        <v-col cols="12" md="1"> Date </v-col>
        <v-col cols="12" md="5" class="text-center">
          <span class="mr-2">{{ rate.start_date }}</span> -
          <span>{{ rate.end_date }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row> -->

  <v-col cols="12" md="12" class="py-0 mt-2 room-card-background">
    <div class="my-2 f-20">Corporate Rates</div>
    <v-card
      class="pb-3 mt-5 room-card-background"
      v-for="(rate, index) in rates"
      :key="'rate_' + index"
    >
      <v-card-text class="px-3 py-0">
        <v-row class="pt-3 custom-card-top-border">
          <v-col cols="4" class="pt-0 pb-2 my-0">
            <small class="room-text-color">FROM DATE</small>
            <div class="mt-2 text--primary">
              {{ rate.start_date }}
            </div>
          </v-col>
          <v-col cols="4" class="pt-0 pb-2 my-0">
            <small class="mb-3 room-text-color">TO DATE</small>
            <div class="mt-2 text--primary">
              {{ rate.end_date }}
            </div>
          </v-col>

          <v-col cols="4" class="pt-0 pb-2 my-0">
            <small class="room-text-color">STARTING FROM</small>
            <div class="mt-2 text--primary">
              {{ rate.starting_price }} &nbsp; €
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["rates"],
};
</script>

<style scoped>
.room-card-background {
  background: #f9f9f9;
}

.room-title-background {
  background: #ededed;
}

.custom-card-top-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.room-text-color {
  color: #b3b3b3;
  font-weight: bold;
}
</style>