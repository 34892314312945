import { render, staticRenderFns } from "./HotelSummary.vue?vue&type=template&id=622398e7&scoped=true&"
import script from "./HotelSummary.vue?vue&type=script&lang=js&"
export * from "./HotelSummary.vue?vue&type=script&lang=js&"
import style0 from "./HotelSummary.vue?vue&type=style&index=0&id=622398e7&scoped=true&lang=css&"
import style1 from "./HotelSummary.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622398e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VCard,VCardText,VChip,VCol,VContainer,VForm,VIcon,VImg,VItem,VItemGroup,VListItem,VListItemAvatar,VListItemContent,VRating,VRow,VScrollYTransition})
