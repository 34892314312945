<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form>
      <v-container>
        <v-row class="mt-3">
          <v-col cols="8">
            <!--Hotel Images (START)-->
            <v-row class="mb-10">
              <v-col cols="12" class="py-0 my-0">
                <gallery
                  :form="form"
                  :isSummary="true"
                  :nonPointerCursor="true"
                ></gallery>
              </v-col>
            </v-row>
            <!--Hotel Images (END)-->

            <!--Hotel Name, Description (START)-->
            <v-row>
              <v-col cols="12" class="mt-3">
                <h5>{{ form.name }}</h5>
              </v-col>
              <v-col
                class="px-0 py-0 my-0"
                cols="12"
                v-if="form.star_rating !== '' && form.star_rating !== null"
              >
                <v-rating
                  v-model="form.star_rating"
                  class="py-0 my-0 ml-2 custom-rating"
                  background-color="orange lighten-3"
                  length="7"
                  color="orange"
                  medium
                  readonly
                ></v-rating>
              </v-col>
              <v-col
                cols="12"
                v-if="form.description !== '' && form.description !== null"
              >
                {{ form.description }}
              </v-col>
            </v-row>
            <!--Hotel Name, Description (END)-->

            <!--Address (START)-->
            <v-row class="mt-3">
              <v-col
                cols="12"
                v-if="form.address !== '' && form.address !== null"
              >
                <v-icon class="mdi mdi-map-marker"></v-icon>
                {{ form.address }}, {{ form.city }},
                {{ form.country }}
              </v-col>
              <v-col class="pt-0" cols="12" md="12" v-if="form.map_location && form.map_location.length > 0">
                <detail-view-map :latLng="form.map_location"></detail-view-map>
              </v-col>
            </v-row>
            <!--Address (END)-->

            <!--Hotel Types (START)-->
            <v-row class="mt-3" v-if="hotelTypes.length > 0">
              <v-col cols="12" md="12" class="py-0 my-0">
                <label class="text-uppercase">Type of Hotel</label>
              </v-col>
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-item-group>
                  <v-container>
                    <v-row>
                      <v-col
                        v-for="(hotelType, index) in hotelTypes"
                        :key="index"
                        class="p-0 m-0 custom-item-column"
                      >
                        <v-item
                          v-slot="{ active, toggle }"
                          :value="hotelType"
                          disabled
                        >
                          <v-card
                            :color="active ? 'primary' : 'default'"
                            class="d-flex align-center"
                            dark
                            height="75"
                            width="75"
                          >
                            <v-scroll-y-transition>
                              <div class="text-center flex-grow-1 black--text">
                                <v-icon
                                  color="primary"
                                  :class="[hotelType.icon]"
                                ></v-icon>
                                <div>
                                  <small>{{ hotelType.name }}</small>
                                </div>
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-col>
            </v-row>
            <!--Hotel Types (END)-->

            <!--Check-in, Check-out, 24 Reception (START)-->
            <v-row
              class="mt-5"
              v-if="form.checkin_time !== '' && form.checkin_time !== null"
            >
              <v-col cols="12" md="4" class="py-0 my-0">
                <label class="text--secondary text-uppercase"
                  >Check-in Time</label
                >
                <div>{{ moment(form.checkin_time, "hh:mm").format("LT") }}</div>
              </v-col>
              <v-col cols="12" md="4" class="py-0 my-0">
                <label class="text--secondary text-uppercase"
                  >Check-out Time</label
                >
                <div>
                  {{ moment(form.checkout_time, "hh:mm").format("LT") }}
                </div>
              </v-col>
              <v-col cols="12" md="4" class="py-0 my-0">
                <label class="text--secondary text-uppercase"
                  >24H Reception</label
                >
                <div>
                  {{
                    form.vendor_specifications.is_reception_always_available ===
                    true
                      ? "YES"
                      : "NO"
                  }}
                </div>
              </v-col>
            </v-row>
            <!--Check-in, Check-out, 24 Reception (END)-->

            <!--Contact (START)-->
            <v-row
              class="mt-5"
              v-if="form.full_name !== '' && form.full_name !== null"
            >
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Contact People</h5>
              </v-col>
              <v-col cols="12" md="8" class="py-0 mt-2">
                <v-card>
                  <v-list-item three-line>
                    <v-list-item-avatar size="80" color="grey">
                      <v-img
                        v-if="form.profile_picture"
                        contained
                        :src="'/' + form.profile_picture"
                        max-width="100px"
                        height="100px"
                        class="mx-auto border rounded-circle"
                      >
                      </v-img>

                      <v-img
                        v-else
                        contained
                        max-width="100px"
                        height="100px"
                        class="mx-auto border rounded-circle"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0 secondary white--text"
                            align="center"
                            justify="center"
                          >
                            <v-icon size="60" color="white"
                              >mli-neutral-avatar</v-icon
                            >
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div>
                        <span
                          v-if="
                            form.person_title !== '' &&
                            form.person_title !== null
                          "
                          >{{ form.person_title }}</span
                        >
                        {{ form.full_name }}
                      </div>
                      <div>{{ form.email }}</div>
                      <div>{{ form.phone }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col
                v-if="
                  form.secondary_full_name !== '' &&
                  form.secondary_full_name !== null
                "
                cols="12"
                md="8"
                class="py-0 mt-2"
              >
                <v-card>
                  <v-list-item three-line>
                    <v-list-item-avatar size="80" color="grey">
                      <v-img
                        v-if="form.secondary_profile_picture"
                        contained
                        :src="'/' + form.secondary_profile_picture"
                        max-width="100px"
                        height="100px"
                        class="mx-auto border rounded-circle"
                      >
                      </v-img>

                      <v-img
                        v-else
                        contained
                        max-width="100px"
                        height="100px"
                        class="mx-auto border rounded-circle"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0 secondary white--text"
                            align="center"
                            justify="center"
                          >
                            <v-icon size="60" color="white"
                              >mli-neutral-avatar</v-icon
                            >
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div>
                        <span
                          v-if="
                            form.secondary_person_title !== '' &&
                            form.secondary_person_title !== null
                          "
                          >{{ form.secondary_person_title }}</span
                        >
                        {{ form.secondary_full_name }}
                      </div>
                      <div>{{ form.secondary_email }}</div>
                      <div>{{ form.secondary_phone }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <!--Contact (END)-->

            <!--Rooms (START)-->
            <v-row
              class="mt-5"
              v-if="
                form.rooms &&
                form.rooms[0] &&
                form.rooms[0].calculated_rooms > 0
              "
            >
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Rooms</h5>
              </v-col>

              <corporate-rates
                v-if="form.corporate_rates && form.corporate_rates.length > 0"
                :rates="form.corporate_rates"
              ></corporate-rates>

              <v-col cols="12" md="12" class="py-0 mt-2 room-card-background">
                <div class="my-4 f-20">Rooms</div>
                <v-card
                  class="pb-3 mt-5 room-card-background"
                  v-for="(room, roomIndex) in form.rooms"
                  :key="'r' + roomIndex"
                >
                  <v-card-text class="px-3 py-0">
                    <!--Room Category (START)-->
                    <div>
                      <v-row
                        class="pt-3  room-title-background custom-card-top-border"
                      >
                        <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                          <small class="room-text-color text-uppercase"
                            >Category</small
                          >
                          <div class="text--primary">
                            {{ room.selected_category }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                          <small class="room-text-color text-uppercase"
                            >Rooms (Calculated)</small
                          >
                          <div class="text--primary">
                            {{ room.calculated_rooms }}
                          </div>
                        </v-col>
                      </v-row>

                      <!--Room Types (START)-->
                      <v-row
                        class="pt-3 mt-3 room-types-border"
                        v-for="(room_type, roomTypeIndex) in room.room_types"
                        :key="'rti' + roomTypeIndex"
                      >
                        <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                          <small class="room-text-color text-uppercase"
                            >Type of Room</small
                          >
                          <div class="text--primary">
                            {{ room_type.selected_type }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-2 my-0">
                          <small class="room-text-color text-uppercase"
                            >Rooms (Calculated)</small
                          >
                          <div class="text--primary">
                            {{ room_type.total_room_types }}
                          </div>
                        </v-col>
                      </v-row>
                      <!--Room Types (END)-->
                    </div>
                    <!--Room Category (END)-->
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!--Rooms (END)-->

            <v-row class="mt-8" v-if="form.vendor_spaces.length > 0">
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Meeting & Event Spaces</h5>
              </v-col>
              <v-col cols="12">
                <summary-spaces :spaces="form.vendor_spaces"></summary-spaces>
              </v-col>
            </v-row>

            <!--Food & Beverages (START)-->
            <v-row
              class="mt-5"
              v-if="form.restaurants.length > 0 || form.bars.length > 0"
            >
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Food & Beverages</h5>
              </v-col>
              <v-col cols="12" md="12" class="py-0 my-0">
                <!--Restaurants (START)-->
                <v-card
                  class="px-3 py-2 mt-3 bg-color-grey"
                  outlined
                  v-if="form.restaurants.length > 0"
                >
                  <v-row class="py-0 my-0">
                    <v-col cols="12" md="12" class="d-flex">
                      <v-icon class="mdi mdi-room-service"></v-icon>
                      <div>
                        Restaurants
                        <v-chip color="default">{{
                          form.restaurants.length
                        }}</v-chip>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-row
                        v-for="(
                          restaurant, restaurantIndex
                        ) in form.restaurants"
                        :key="'ri' + restaurantIndex"
                      >
                        <v-col
                          cols="12"
                          md="12"
                          :class="{
                            'room-types-border':
                              form.restaurants.length > restaurantIndex + 1,
                          }"
                          class="py-0 mt-0 mb-2"
                        >
                          <div class="font-weight-bold">
                            {{ restaurant.name }}
                          </div>
                          <div>
                            {{ restaurant.cuisines.toString() }} -
                            {{ restaurant.price_range }} -
                            {{ restaurant.seating_capacity }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <!--Restaurants (END)-->

                <!--Bars (START)-->
                <v-card
                  class="px-3 py-2 mt-3 bg-color-grey"
                  outlined
                  v-if="form.bars.length > 0"
                >
                  <v-row class="py-0 my-0">
                    <v-col cols="12" md="12" class="d-flex">
                      <v-icon class="mdi mdi-glass-cocktail"></v-icon>
                      <div>
                        Bars
                        <v-chip color="default">{{ form.bars.length }}</v-chip>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-row
                        v-for="(bar, barIndex) in form.bars"
                        :key="'bi' + barIndex"
                      >
                        <v-col
                          cols="12"
                          md="12"
                          :class="{
                            'room-types-border':
                              form.bars.length > barIndex + 1,
                          }"
                          class="py-0 mt-0 mb-2"
                        >
                          <div class="font-weight-bold">{{ bar.name }}</div>
                          <div>
                            {{ bar.bar_type }} - {{ bar.price_range }} -
                            {{ bar.seating_capacity }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <!--Bars (END)-->
              </v-col>
            </v-row>
            <!--Food & Beverages (END)-->

            <!--Surrounding & Activities (START)-->
            <v-row class="mt-5" v-if="form.ptcs.length > 0">
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Surrounding & Activities</h5>
              </v-col>
            </v-row>
            <v-row v-if="form.ptcs.length > 0">
              <v-col cold="12" md="12">
                <v-item-group readonly>
                  <v-container>
                    <v-row>
                      <v-col
                        v-for="(hotelPtc, index) in form.ptcs"
                        :key="index"
                        class="p-0 m-0 custom-item-column"
                      >
                        <v-item v-slot="{ active, toggle }" :value="hotelPtc">
                          <v-card
                            :color="active ? 'primary' : 'default'"
                            class="d-flex align-center"
                            dark
                            height="75"
                            width="75"
                          >
                            <v-scroll-y-transition>
                              <div
                                class="text-center flex-grow-1"
                                :class="{ 'black--text': active === false }"
                              >
                                <v-icon
                                  :color="active === false ? 'primary' : ''"
                                  :class="[hotelPtc.icon]"
                                ></v-icon>
                                <div>
                                  <small>{{ hotelPtc.name }}</small>
                                </div>
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-col>
            </v-row>
            <!--Surrounding & Activities (END)-->

            <!--Parking Spaces (START)-->
            <v-row
              class="mt-3"
              v-if="form.vendor_specifications.total_parking_spaces > 0"
            >
              <v-col cols="12" md="12" class="py-0 my-0">
                <label class="text--secondary">Parking Spaces</label>
                <div>{{ form.vendor_specifications.total_parking_spaces }}</div>
              </v-col>
            </v-row>
            <!--Parking Spaces (END)-->

            <!--Tours & Activities (START)-->
            <v-row class="mt-5" v-if="form.activities.length > 0">
              <v-col cols="12" md="12" class="py-0 my-0">
                <label class="text--secondary">Tours & Activities</label>
              </v-col>
              <v-col cols="12" md="12" class="py-0 my-0">
                <v-row
                  class="py-0 my-0"
                  v-for="(activity, activityIndex) in form.activities"
                  :key="'esi' + activityIndex"
                >
                  <v-col cols="12" md="10" class="pt-0 pb-1 pl-3 my-0">
                    <v-icon class="mdi mdi-chevron-right"></v-icon>
                    {{ activity.name }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--Tours & Activities (END)-->

            <!--Opening Seasons (START)-->
            <v-row
              class="mt-5"
              v-if="form.seasonal_timings && form.seasonal_timings.length"
            >
              <v-col cols="12" md="12" class="py-0 my-0">
                <h5>Opening Seasons</h5>
              </v-col>
              <v-col cols="12" md="12">
                <v-row
                  class="my-0"
                  v-for="(openingSeason, index) in form.seasonal_timings"
                  :key="'seasons_' + index"
                >
                  <v-col cols="12" md="6"> Date </v-col>
                  <v-col cols="12" md="6" class="text-right">
                    <span class="mx-3">{{ openingSeason.start_date }}</span> -
                    <span class="mx-3">{{ openingSeason.end_date }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--Opening Seasons (END)-->
          </v-col>

          <!--Amenities (START)-->
          <v-col cols="4" class="bg-color-grey" v-if="hotelFeatures.length > 0">
            <label class="font-weight-bold">Amenities</label>
            <v-item-group
              v-for="(hotel_feature, index) in form.hotel_features"
              :key="'eq' + index"
            >
              <v-list-item v-if="hotel_feature.selected">
                <label :class="{ 'text--secondary': !hotel_feature.selected }"
                  ><i :class="hotel_feature.icon"></i>
                  {{ hotel_feature.name }}</label
                >
              </v-list-item>
            </v-item-group>
          </v-col>
          <!--Amenities (END)-->
        </v-row>
      </v-container>
    </v-form>

    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="getIsVendorUpdate && getVendor && getVendor.status === 'active'"
      @click="submit('save')"
      :loading="getVendorUpdateStatus === 1"
      >Update Hotel</v-btn
    >
    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="getIsVendorUpdate && getVendor && getVendor.status !== 'active'"
      @click="submit('draft')"
      :loading="getVendorUpdateStatus === 1"
      >Update Hotel</v-btn
    >
    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="!getIsVendorUpdate"
      :loading="getVendorAddStatus === 1"
      @click="submit('draft')"
      >Create Hotel</v-btn
    >
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import CorporateRates from "@/components/CorporateRates.vue";
const Gallery = () =>
  import(/* webpackChunkName: "gallery" */ "@/components/Gallery");
const SummarySpaces = () =>
  import(
    /* webpackChunkName: "summary-spaces" */ "@/components/sections/SummarySpaces"
  );
const DetailViewMap = () =>
    import(
        /* webpackChunkName: "detail-view-map" */ "@/components/DetailViewMap"
        );
export default {
  name: "HotelSummary",
  props: ["name", "form"],
  components: { Gallery, SummarySpaces, DetailViewMap, CorporateRates },
  data: () => ({}),
  computed: {
    ...mapGetters([
      "getVendors",
      "getVendor",
      "getIsVendorUpdate",
      "getVendorAddStatus",
      "getVendorUpdateStatus",
    ]),
    hotelTypes() {
      return _.filter(this.form.hotel_types, { selected: true });
    },
    hotelFeatures() {
      return _.filter(this.form.hotel_features, { selected: true });
    },
  },
  methods: {
    setDraft(draft, index) {
      let checkbox = this.$refs["selectedDraft" + index][0];
      checkbox.selected = true;
      //forEach((val, index) => this.selected[index] = true)
    },
    draftTypes(draft) {
      let types = [];
      if (draft.type === "hotel") {
        let hotelTypes = _.filter(draft.hotel_types, { selected: true });
        _.forEach(hotelTypes, (hotelType, index) => {
          if (index < 4) {
            types.push(hotelType);
          }
        });
      } else if (draft.type === "location") {
        _.forEach(draft.location_types, (locationType, index) => {
          if (index < 4) {
            types.push(locationType);
          }
        });
      }
      return types;
    },
    moment,
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
  },
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}
.carousel-custom-style {
  border-radius: 20px;
}

.room-title-background {
  background: #ededed;
}

.room-text-color {
  color: #b3b3b3;
  font-weight: bold;
}
.custom-card-top-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.room-card-background {
  background: #f9f9f9;
}
.room-types-border {
  border-bottom: 1px solid #e3e3e3;
}
.custom-draft-style {
  width: 100%;
}
.image-container {
  max-width: 100px;
  max-height: 100px;
  height: 100px;
  width: 120px;
  background-color: gray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-card-checkbox {
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 5px;
}
</style>
<style>
.custom-rating button {
  padding: 0 !important;
}
</style>
